import React from 'react';

export const Paragraph = ({
  as: Component,
  width,
  level,
  className,
  children,
}) => {
  const Tag = Component || 'p';
  const maxWidth = width || 'prose';
  const bodyLevel = (+level || 1) === 2 ? 'body-2' : 'body-1';
  return (
    <Tag className={`${bodyLevel} max-w-${maxWidth} ${className || ''}`}>
      {children}
    </Tag>
  );
};

const headlineLevels = [
  'headline-0',
  'headline-1',
  'headline-2',
  'headline-3',
  'headline-4',
  'headline-5',
  'headline-6',
];
export const Headline = ({ as: Component, level, children, className }) => {
  const Tag = Component || 'h6';
  // using map instead of interpolation to work around purge css
  const headlineLevel = +level || 6;
  let levelClasses = 'headline-6';
  if (headlineLevel >= 0 && headlineLevel < 6)
    levelClasses = headlineLevels[headlineLevel];
  return <Tag className={`${levelClasses} ${className || ''}`}>{children}</Tag>;
};

export const Display = ({ as: Component, level, className, children }) => {
  const Tag = Component || 'h6';
  // PurgeCss needs for the full string of class to appear in code otherwise it is purged so we can't do display-${level}
  const displayLevel = (+level || 1) === 2 ? 'lg:display-2' : 'lg:display-1';
  return (
    <Tag
      className={`${displayLevel} sm:text-5xl text-4xl  font-headline ${
        className || ''
      }   leading-none`}
    >
      {children}
    </Tag>
  );
};

export const Subtitle = ({
  as: Component,
  width,
  level,
  className,
  children,
}) => {
  const Tag = Component || 'p';
  const maxWidth = width || 'prose';
  return (
    <Tag
      className={`subtitle-${level || 1} max-w-${maxWidth} ${className || ''}`}
    >
      {children}
    </Tag>
  );
};

export const Caption = ({ as: Component, width, className, children }) => {
  const Tag = Component || 'p';
  const maxWidth = width || 'prose';
  return (
    <Tag className={`caption max-w-${maxWidth} ${className || ''}`}>
      {children}
    </Tag>
  );
};

export const Overline = ({ as: Component, className, children }) => {
  const Tag = Component || 'p';
  return <Tag className={`overline ${className || ''}`}>{children}</Tag>;
};

const underlineWidthMap = {
  0: 'border-b-0',
  1: 'border-b',
  2: 'border-b-2',
  4: 'border-b-4',
  8: 'border-b-8',
};
export const Underlined = ({
  as: Component,
  width,
  spacing,
  color,
  className,
  children,
}) => {
  const Tag = Component || 'div';
  const strokeWidth = underlineWidthMap[width || 1] || underlineWidthMap[1];
  const padding = spacing || 1;
  return (
    <Tag
      className={`underlined__type ${strokeWidth} pb-${padding} inline-block border-${
        color || 'black'
      } ${className || ''}`}
    >
      {children}
    </Tag>
  );
};

export default Paragraph;
