import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `charset`,
          content: 'UTF-8',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata.author || 'Kyosimba Dev Team',
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords.join(','),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `${url || 'https://kyosimba.com/'}`,
        },
        {
          property: `og:image`,
          content: `${url || 'https://kyosimba.com/cover.png'}`,
        },
        {
          property: 'og:image:alt',
          content:
            'An ambidextrous Busy Bee at the office doing many things at once all while sipping their coffee',
        },
        {
          property: 'og:site_name',
          content: 'Kyosimba',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@kyosimbatech',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: 'twitter:text:title',
          content: title,
        },
        {
          name: `twitter:site`,
          content: `@kyosimbatech`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        { name: 'twitter:image', content: 'https://kyosimba.com/cover.png' },
        {
          name: 'twitter:image:alt',
          content:
            'An ambidextrous Busy Bee at the office doing many things at once all while sipping their coffee',
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
