import React from 'react';
import Navbar from './navbar';
import { Headline, Underlined } from './typography';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-16">
        <Navbar />
      </header>
      <main className="max-w-full px-4 lg:px-16 py-2">{children}</main>
      <footer className="px-4 lg:px-16 flex lg:flex-col flex-grow lg:flex-grow-0 w-full justify-center lg:justify-start">
        <ul className="flex flex-row max-w-max lg:my-4 2xl:my-8">
          <li className="h-8">
            <a
              href="//www.facebook.com/kyosimbatech"
              target="__black"
              rel="noopener noreferrer"
              className="inline-block cursor-pointer"
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39 24.0919C39 15.7567 32.2846 9 24.0019 9C15.7154 9.00187 9 15.7567 9 24.0937C9 31.6247 14.4856 37.8676 21.6547 39V28.4544H17.8489V24.0937H21.6584V20.766C21.6584 16.9846 23.8988 14.8961 27.324 14.8961C28.9663 14.8961 30.6817 15.1905 30.6817 15.1905V18.9025H28.79C26.9284 18.9025 26.3472 20.0667 26.3472 21.261V24.0919H30.5054L29.8418 28.4526H26.3453V38.9981C33.5144 37.8658 39 31.6228 39 24.0919Z"
                  fill="black"
                />
              </svg>
            </a>
          </li>
          <li className="h-8">
            <a
              href="//www.linkedin.com/company/kyosimba/"
              target="__black"
              rel="noopener noreferrer"
              className="inline-block cursor-pointer"
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 9C15.7156 9 9 15.7156 9 24C9 32.2844 15.7156 39 24 39C32.2844 39 39 32.2844 39 24C39 15.7156 32.2844 9 24 9ZM20.3281 30.2172H17.2906V20.4422H20.3281V30.2172ZM18.7906 19.2422C17.8312 19.2422 17.2109 18.5625 17.2109 17.7219C17.2109 16.8641 17.85 16.2047 18.8297 16.2047C19.8094 16.2047 20.4094 16.8641 20.4281 17.7219C20.4281 18.5625 19.8094 19.2422 18.7906 19.2422ZM31.4219 30.2172H28.3844V24.8C28.3844 23.5391 27.9438 22.6828 26.8453 22.6828C26.0063 22.6828 25.5078 23.2625 25.2875 23.8203C25.2063 24.0188 25.1859 24.3 25.1859 24.5797V30.2156H22.1469V23.5594C22.1469 22.3391 22.1078 21.3188 22.0672 20.4406H24.7063L24.8453 21.7984H24.9062C25.3062 21.1609 26.2859 20.2203 27.925 20.2203C29.9234 20.2203 31.4219 21.5594 31.4219 24.4375V30.2172Z"
                  fill="black"
                />
              </svg>
            </a>
          </li>
          <li className="h-8">
            <a
              href="//api.whatsapp.com/send?phone=256775193044&text="
              target="__black"
              rel="noopener noreferrer"
              className="inline-block cursor-pointer"
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.7564 15.9182C32.2688 8.99977 23.1065 6.94294 16.0011 11.2436C9.08267 15.5442 6.83886 24.8935 11.3265 31.8119L11.7005 32.3729L10.2046 37.9824L15.8141 36.4865L16.3751 36.8605C18.8059 38.1694 21.4237 38.9173 24.0415 38.9173C26.8462 38.9173 29.651 38.1694 32.0818 36.6735C39.0002 32.1859 41.0571 23.0236 36.7564 15.9182V15.9182ZM32.8297 30.316C32.0818 31.4379 31.1469 32.1859 29.838 32.3729C29.09 32.3729 28.1551 32.7468 24.4154 31.251C21.2367 29.7551 18.6189 27.3243 16.749 24.5195C15.6271 23.2106 15.0662 21.5277 14.8792 19.8449C14.8792 18.349 15.4402 17.0401 16.3751 16.1052C16.749 15.7312 17.123 15.5442 17.497 15.5442H18.4319C18.8059 15.5442 19.1798 15.5442 19.3668 16.2922C19.7408 17.2271 20.6757 19.4709 20.6757 19.6579C20.8627 19.8449 20.8627 20.2189 20.6757 20.4058C20.8627 20.7798 20.6757 21.1538 20.4887 21.3408C20.3018 21.5277 20.1148 21.9017 19.9278 22.0887C19.5538 22.2757 19.3668 22.6497 19.5538 23.0236C20.3018 24.1455 21.2367 25.2674 22.1716 26.2024C23.2935 27.1373 24.4154 27.8852 25.7243 28.4462C26.0983 28.6332 26.4723 28.6332 26.6592 28.2592C26.8462 27.8852 27.7811 26.9503 28.1551 26.5763C28.5291 26.2024 28.7161 26.2024 29.09 26.3893L32.0818 27.8852C32.4558 28.0722 32.8297 28.2592 33.0167 28.4462C33.2037 29.0071 33.2037 29.7551 32.8297 30.316V30.316Z"
                  fill="black"
                />
              </svg>
            </a>
          </li>
        </ul>
        <div className="lg:flex lg:flex-row hidden">
          <ul
            aria-label="List of Contact Information"
            className="px-2 lg:flex-grow"
          >
            <li aria-label="Phone Number" className="body-1">
              Call +256 94602126
            </li>
            <Underlined as="li" aria-label="Email Address" className="body-1">
              sales@kysoimba.com
            </Underlined>
          </ul>
          <div>
            <Headline level="6" as="h1" className="mb-4">
              Kyosimba Technologies Limited
            </Headline>
            <div className="flex w-full">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.2616 28.1369L28.2833 30.0935C26.825 31.5235 24.9333 33.3635 22.6066 35.6135C21.9073 36.2897 20.9727 36.6676 20 36.6676C19.0272 36.6676 18.0926 36.2897 17.3933 35.6135L11.575 29.9535C10.8433 29.2352 10.2316 28.6302 9.73829 28.1369C7.7089 26.1073 6.3269 23.5216 5.76705 20.7066C5.2072 17.8917 5.49464 14.9739 6.59302 12.3223C7.69141 9.67071 9.5514 7.40436 11.9378 5.80984C14.3242 4.21533 17.1299 3.36426 20 3.36426C22.87 3.36426 25.6757 4.21533 28.0621 5.80984C30.4485 7.40436 32.3085 9.67071 33.4069 12.3223C34.5053 14.9739 34.7927 17.8917 34.2329 20.7066C33.673 23.5216 32.291 26.1073 30.2616 28.1369V28.1369ZM24.1666 18.3335C24.1666 17.2285 23.7276 16.1686 22.9462 15.3872C22.1648 14.6058 21.105 14.1669 20 14.1669C18.8949 14.1669 17.8351 14.6058 17.0537 15.3872C16.2723 16.1686 15.8333 17.2285 15.8333 18.3335C15.8333 19.4386 16.2723 20.4984 17.0537 21.2798C17.8351 22.0612 18.8949 22.5002 20 22.5002C21.105 22.5002 22.1648 22.0612 22.9462 21.2798C23.7276 20.4984 24.1666 19.4386 24.1666 18.3335V18.3335Z"
                  fill="#151515"
                />
              </svg>
              <Underlined as="ul" className="ml-2">
                <li className="body-1">B36-A Bagala Shopping Centre</li>
                <li className="body-1">
                  Bukoto-Ntinda Road.
                  <span className="block">Kampala, Uganda</span>
                </li>
              </Underlined>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
